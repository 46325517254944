img {
    margin: 0;
}

.btnWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    justify-content: space-between;
}

.remove {
    background: #FFFFFF;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0B5A45;
    cursor: pointer;
    max-width: 205px;
    height: 42px;
    width: 100%;
    justify-content: center;
}

.continue {
    /* padding: 12px 54px; */
    background: #0B5A45;
    border: 1px solid #0B5A45;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    max-width: 205px;
    width: 100%;
    height: 42px;
    justify-content: center;
}

.delete {
    margin-top: 25px;
    padding: 12px 73px;
    background: rgb(250, 100, 100);
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.disabled {
    opacity: 0.6;
    pointer-events: none;
}

.stepTwoBlock {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.deleteBtn {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EB4D4B;
    border-radius: 8px;
}

.deleteBtn:hover {
    cursor: pointer;
}

.gearIcon {
    height: 20px;
    width: 20px;
    animation: rotate infinite 2s linear;
}

.centerWrapper {
    display: flex;
    align-items: center;
    justify-content: center !important;
    /* width: 100%; */
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 450px) {
    .continue {}

    .remove {}
}

@media (max-width: 400px) {
    .continue {}

    .remove {}
}