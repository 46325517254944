.wrapper {
  max-height: 100vh;

  padding: 0 40px 20px;

  display: flex;
  justify-content: center;
  padding-top: 6%;
}

.title span {
  font-size: 24px;
  font-weight: 500;
  color: #000;
}

.title {
  margin: 50px 0 20px;
}

.wrapper .signup {
  max-width: 700px;
  width: 100%;
  min-height: max-content;
  background-color: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  display: flex;
  justify-content: center;

  position: relative;
}

.signupForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper .changeLangWrapper {
  position: absolute;
  top: 21px;
  right: 25px;
  cursor: pointer;
}

.wrapper .changeLang {
  display: flex;
  align-items: center;
}

.wrapper .changeLangWrapper span {
  color: #52575c;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
  margin-right: 13px;
}

.wrapper .changeLang svg {
  color: #a0a4a8;
}

.wrapper .changeLangWrapper .select {
  border-radius: 4px;
  margin-top: 4px;
}

.wrapper .changeLangWrapper .select span {
  margin: 0;
}

.inputBlock {
  display: flex;
  flex-direction: column;

  max-width: max-content;
  margin: 0 auto;
  position: relative;
}

.inputBlock label {
  font-size: 16px;
  font-weight: 500;
  padding-left: 16px;
  margin-bottom: 4px;
}

.inputBlock input {
  min-width: 326px;
  min-height: 56px;
  margin: 0;
  padding: 0;
  border: 1px solid #e4e4e4;
  border-radius: 8px;

  font-size: 16px;
  font-weight: 400;
  padding: 0 17px;
  letter-spacing: 0.15px;
}

.inputBlock input:focus {
  outline: none;
}

.inputBlock input::-webkit-input-placeholder {
  color: #bababa;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-size: 16px;
}

.inputBlock:nth-child(2),
.inputBlock:nth-child(3) {
  margin-top: 8px;
  position: relative;
}

.inputBlock .typeChangeBtn {
  position: absolute;
  top: 55%;
  right: 16px;
  color: #bababa;

  cursor: pointer;
}

.inputBlock .typeChangeBtn svg {
  min-width: 22px;
}

.signupBtnWrapper {
  text-align: center;
  margin-top: 24px;
}

.signupBtnWrapper .loginBtn {
  min-width: 156px;
  min-height: 36px;
  border-radius: 8px;
  border: 1px solid #10533A;
  background-color: #10533A;
  cursor: pointer;
  color: #ffffff;

  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.165px;
}

.signupBtnWrapper .loginBtn:disabled {
  cursor: default;
}

.loginWrapper {
  margin: 16px 0 40px 0;
  padding-bottom: 1px;

  font-size: 14px;
}

.noProfile {
  font-weight: 400;
  color: #787878;
  margin-right: 4px;
}

.loginLink {
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}

.loginLink:hover {
  text-decoration: underline;
}

.agreeInfoWrapper {
  max-width: 360px;
  margin: 0 auto;
}

.agreeInfo {
  display: flex;
  align-items: center;
}

.agreeInfo span {
  font-size: 14px;
  margin-left: 8px;
  line-height: 16px;
}

.agreeInfo a {
  color: #10533A;
  text-decoration: none;
}

.agreeInfo a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;

  position: relative;
}

.checkbox+label svg {
  position: absolute;
  color: #fff;
  left: 2px;
  max-height: 14px;
}

.checkbox+label::before {
  content: "";
  display: inline-block;
  width: 18px;
  max-width: 18px;
  height: 18px;
  max-height: 18px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #e4e4e4;
  border-radius: 0.25em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  color: #fff;
}

.checkbox:checked+label::before {
  border-color: #10533A;
  background-color: #10533A;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

.errorWrapper {
  margin: 20px 0 24px;
  min-width: 548px;
  max-width: 548px;
  min-height: 67px;
  border-radius: 4px;
  background-color: #ffecec;
  display: flex;
  align-items: flex-start;

  border: 1px solid #e4e4e4;
}

.errorWrapper img {
  margin-left: 55px;
  margin: 12px 13px 0 55px;
  max-height: max-content;
}

.errorWrapper .errorContent {
  display: flex;
  flex-direction: column;
}

.errorWrapper .errorContent {
  margin-top: 14px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.165px;
  color: #949494;
}

.errorWrapper .errorContent .errorTitle {
  font-weight: 500;
  display: block;
  padding-bottom: 3px;
}

.errorWrapper .errorContent a {
  color: #000;
  font-weight: 500;
}

.passValidateWrapper {
  position: absolute;
  color: #ff6464;
  max-width: 362px;
  margin: 2px auto;
  font-size: 14px;
  font-weight: 400;
  background-color: #fff;
  z-index: 999;
  right: -130px;
  top: -130px;
  padding: 10px;
  border-radius: 8px;
  cursor: default;

  border: 1px solid #e4e4e4;
}

.passValidateWrapper ul {
  list-style-type: disc;
  margin: 2px 0;
  padding-left: 17px;
}

.inputWithIcon {
  min-width: 295px !important;
  min-height: 56px !important;
  padding: 0 45px 0px 17px !important;
}

@media (max-height: 820px) {
  .wrapper {
    padding-top: 25px;
  }
}

@media (max-height: 730px) {
  .wrapper {
    padding-bottom: 25px;
  }
}

@media (max-height: 570px) {
  .wrapper {
    height: 100%;
  }

  .title {
    margin: 20px 0 20px;
  }

  .wrapper .signup {
    min-height: 540px;
  }
}

@media (max-width: 700px) {
  .passValidateWrapper {
    position: static;
    margin-top: 6px;
  }
}

@media (max-width: 660px) {
  .errorWrapper {
    max-width: 415px;
    min-width: 415px;
  }

  .errorWrapper img {
    margin-left: 25px;
  }

  .errorWrapper .errorContent {
    padding: 0 0 14px 0;
  }
}

@media (max-width: 570px) {
  .title span {
    font-size: 20px;
  }

  .inputBlock label {
    font-size: 14px;
  }

  .agreeInfo span {
    font-size: 12px;
  }

  .inputBlock input {
    min-width: 280px;
    min-height: 46px;
  }
  .inputWithIcon {
    padding-right: 50px !important;
    min-width: 247px !important;
    min-height: 46px !important;
  }

  .passValidateWrapper {
    max-width: 294px;
  }

  .agreeInfo {
    padding: 0 10px;
  }
}

@media (max-width: 520px) {
  .agreeInfo {
    max-width: 310px;
  }

  .errorWrapper {
    max-width: 330px;
    min-width: 330px;
    margin: 20px auto;
  }

  .errorWrapper img {
    margin-left: 10px;
  }

  .errorWrapper .errorContent span {
    line-height: 20px;
  }
}

@media (max-width: 430px) {
  .inputBlock input {
    min-width: 220px;
  }

  .inputWithIcon {
    padding-right: 50px !important;
    min-width: 247px !important;
    min-height: 46px !important;
  }

  .passValidateWrapper {
    max-width: 230px;
  }

  .agreeInfo {
    padding: 0 12px;
    max-width: 256px;
  }

  .errorWrapper {
    max-width: 256px;
    min-width: 256px;
  }

  .errorWrapper img {
    margin-left: 5px;
  }
}

@media (max-width: 430px) {
  .inputBlock input {
    min-width: 205px;
  }
  .inputWithIcon {
    padding-right: 40px !important;
    min-width: 182px !important;
    min-height: 46px !important;
  }
}