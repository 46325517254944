.rowReplenishment {
    position: relative;
    background-color: #fff;
    min-height: 41px;
    max-height: 41px;

    margin: 8px 0;
    border-radius: 8px;
    display: flex;
    align-items: center;

    color: #323232;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
}

.token {
    width: 100px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.info {
    min-width: 24px;
    min-height: 24px;
    position: relative;
}

.rowReplenishment div[data-title]:hover::after {
    content: attr(data-title);
    position: absolute;
    top: 100%;
    z-index: 10;
    padding: 5px 10px;
    background: rgba(16, 83, 58, 0.9);
    color: #ffffff;
    border-radius: 8px;
    text-wrap: nowrap;
    right: 30%;
}

.rowReplenishment div {
    position: relative;
}

.rowReplenishment div:nth-child(1) {
    margin-left: 16px;
    /* flex: 1; */
    /* flex: 1 40px; */
    /* max-width: 100px; */
    /* margin-left: 14px; */
}

.rowReplenishment div:nth-child(2) {
    margin-left: 26px;
    min-width: 92px;
    max-width: 92px;
    /* flex: 1; */
    /* flex: 1 40px; */
}

.rowReplenishment div:nth-child(3) {
    margin-left: 10px;
    min-width: 136px;
    max-width: 136px;
    /* flex: 1; */
    /* flex: 1 50px; */
}

.rowReplenishment div:nth-child(4) {
    margin-left: 30px;
    min-width: 150px;
    max-width: 150px;
    /* flex: 1; */
    /* flex: 1 40px; */
}

.rowReplenishment div:nth-child(5) {
    position: relative;
    margin-left: 18px;
    min-width: 148px;
    max-width: 150px;
    min-height: 1px;
    /* flex: 1; */
    /* flex: 1 40px; */
    /* text-align: center; */
}

.rowReplenishment div:nth-child(5):hover {
    cursor: pointer;
}

.rowReplenishment div:nth-child(6) {
    margin-left: 5px;
    min-width: 86px;
    max-width: 135px;

    /* flex: 1; */
    /* flex: 1 30px; */
    /* text-align: center; */
}

.ellipsis {
    margin-left: 0 !important;
    overflow-x: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.table .rowReplenishment img {
    max-width: 24px;
    max-height: 24px;
    position: absolute;
    right: 2px;
}

.info {
    min-width: 24px;
    min-height: 24px;
    position: relative;
}

.info:hover::after {
    content: attr(data-info);

    position: absolute;
    top: -116px;
    right: 30%;

    min-width: 176px;
    min-height: 80px;
    max-height: 80px;

    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    z-index: 90;

    -webkit-box-shadow: 0px 7px 15px -6px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 7px 15px -6px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 7px 15px -6px rgba(0, 0, 0, 0.5);
}

.info:hover::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 20px;
    border: 10px solid transparent;
    z-index: 99;
    border-top: 10px solid #fff;
}

.info:hover::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 20px;
    border: 10px solid transparent;
    z-index: 99;
    border-top: 10px solid #fff;
}

.image {
    max-width: 24px;
    max-height: 24px;
    position: absolute;
    right: 2px;
}