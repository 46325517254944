.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);
  z-index: 9999;
  height: 100%;
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #323232;
}

.closeBtn {
  cursor: pointer;
}

.stepHandler {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.step {
  width: 33%;
  height: 6px;
  background: #EFEFEF;
}

.active {
  background: #0B5A45;
}

.content {
  position: relative;
  max-width: 480px;
  width: 100%;
  height: max-content;
  border-radius: 8px;
  background-color: #fff;
  padding: 24px;
  -webkit-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
  box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
  height: fit-content;
  /* overflow-y: auto; */
}

@media (max-height: 621px) {
  .wrapper {
    align-items: initial;
  }

}