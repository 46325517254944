.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 38px;
}

.logoWrapper img {
  max-height: 58px;
}

.logoWrapper span {
  font-size: 12px;
  color: #bfbfbf;
  font-weight: 400;
  padding-left: 21px;
  cursor: default;
}

@media (max-width: 700px) {
  .logoWrapper {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .logoWrapper img {
    max-height: 40px;
  }
}
