* {
  font-family: "Roboto", sans-serif;
}

input {
  outline: none;
}

.App {
  min-height: 100vh;
  max-width: 100vw;
  width: 100vw;
  background-color: #f8f8f8;
  display: flex;
  flex-flow: column;
}

.App button:disabled {
  opacity: 0.7;
  cursor: default !important;
}

:root {
  --toastify-toast-min-height: 48px !important;
  --toastify-toast-max-height: 48px !important;
  --toastify-toast-min-width: 297px !important;

  --toastify-icon-color-success: #fff !important;
}

@media (max-height: 810px) {
  .App {
    max-height: max-content;
  }
}