.nav {
  min-width: 200px;
  width: 200px;
  /* фиксируем и выставляем высоту панели на максимум */
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  margin: 0;
  /* сдвигаем (прячем) панель относительно левого края страницы */
  left: -200px;
  /* внутренние отступы */
  padding-right: 60px;
  /* плавный переход смещения панели */
  -webkit-transition: left 0.3s;
  -moz-transition: left 0.3s;
  transition: left 0.3s;
  background: linear-gradient(#10533A 83px, #fff 83px);
  z-index: 2000;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.nav[data-ismobileclose="true"] {
  background: linear-gradient(#10533A 60px, #f8f8f8 60px);
  height: 60px;
  overflow: hidden;
}

.nav .emailBlock {
  min-height: 83px;
  position: relative;
}

.navToggle {
  position: absolute;
  /* относительно левого края панели */
  left: 210px;
  /* отступ от верхнего края панели */
  top: 1em;
  /* внутренние отступы */
  padding: 0.5em;
  /* определяем цвет фона переключателя
     * чаще вчего в соответствии с цветом фона панели
    */
  background: inherit;
  /* цвет текста */
  color: #ffffff;
  /* вид курсора */
  cursor: pointer;
  /* размер шрифта */
  font-size: 1.2em;
  /* всегда поверх других элементов страницы */
  z-index: 2001;
  /* анимируем цвет текста при наведении */
  -webkit-transition: color 0.25s ease-in-out;
  -moz-transition: color 0.25s ease-in-out;
  transition: color 0.25s ease-in-out;

  white-space: nowrap;
}

.navToggle span {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  vertical-align: 5px;
  letter-spacing: 0.25px;

  padding-left: 22px;
}

/**
 * Скрытый чекбокс (флажок)
 * невидим и недоступен :)
 * имя селектора атрибут флажка
 */

[id="nav-toggle"] {
  position: absolute;
  display: none;
}

/**
 * изменение положения переключателя
 * при просмотре на мобильных устройствах
 * когда навигация раскрыта, распологаем внутри панели
*/

[id="nav-toggle"]:checked~.nav>.navToggle {
  left: 12.4px;
  top: 1em;

  color: #ffffff;
}

/**
 * Когда флажок установлен, открывается панель
 * используем псевдокласс:checked
 */

[id="nav-toggle"]:checked~.nav {
  left: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

.nav ul {
  padding-left: 20px;

  display: flex;
  flex-direction: column;

  margin: 68px 0 0 0;
  list-style-type: none;

  width: max-content;

  line-height: 48px;
}

.nav ul .menuLink {
  color: #000000;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.25px;
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
}

.nav ul .menuLink span {
  margin-left: 20px;
}

.nav ul a.active {
  font-weight: 500;
}

.nav ul a.active svg {
  color: #000;
}

.nav .bottomWrapper, .nav .bottomWrapperMin {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 490px;
  left: 21px;
}

.nav .bottomWrapperMin {
  top: 335px;
}

.nav .bottomItem {
  height: 48px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000000;
  cursor: pointer;
}

.nav .bottomItem span {
  margin-left: 22px;
  line-height: 24px;
}

.nav .bottomItem img {
  width: 24px;
  height: 24px;
}

.nav .logout img {
  margin-left: 3px;
}

.nav .logout span {
  margin-left: 19px;
}

.nav .shortMenu {
  margin: 0;
  height: 135px;

  position: absolute;
  top: 155px;
  right: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav ul svg {
  min-height: 26px;
  min-width: 26px;
  color: #bebdbd;
}

.nav .email {
  width: 230px !important;
}

.nav .emailBlock[data-open="true"]:after {
  z-index: 9999;
  content: "";
  text-align: right;
  position: absolute;
  top: 28px;
  right: -61px;
  width: 20%;
  height: 1.3em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #10533A 90%);
  pointer-events: none;
}

@media screen and (min-width: 320px) {

  html,
  body {
    margin: 0;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 320px) {

  html,
  body {
    margin: 0;
    overflow-x: hidden;
  }

  .nav {
    width: 100%;
    box-shadow: none;
    padding-right: 0;
    width: 265px;
  }
}

.hoverAll {
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  top: 0;
  z-index: 999;
  backdrop-filter: blur(3px);
}

@media (max-width: 700px) {
  .nav {
    background: linear-gradient(#10533A 60px, #f8f8f8 60px);
    max-height: 425px;
    transition-property: width;
    position: absolute;

    z-index: 9999;
  }

  .nav[data-ismobileclose="false"] {
    width: 100%;
    position: fixed;
  }

  .nav .emailBlock {
    min-height: 60px;
  }

  .nav .navToggle {
    top: 0.5em !important;
  }

  .nav ul {
    margin: 42px 0 0 0;
  }

  .nav .bottomWrapper {
    top: 310px;
  }
}

@media (max-height: 590px) {
  .nav ul {
    margin: 25px 0 0 0;
  }
}

@media screen and (max-height: 590px) and (min-width: 700px) {
  .nav .bottomWrapper {
    top: 295px;
  }

  .nav .bottomWrapperMin {
    top: 150px
  }
}

@media (max-height: 390px) {
  .nav ul {
    margin: 10px 0 0 0;
  }

  .nav ul .menuLink {
    font-size: 13px;
  }

  .bottomItem {
    font-size: 13px;
  }

  .image {
    min-width: 20px !important;
    min-height: 20px !important;
    width: 20px !important;
    height: 20px !important;
  }

  li {
    height: 40px;
  }

  .nav .bottomWrapper {
    top: 255px;
  }

  .nav .shortMenu {
    top: 100px;
  }

  .shortLink {
    margin-bottom: 15px;
  }
}

@media (max-height: 350px) {
  li {
    height: 35px;
  }

  .nav .bottomWrapper a {
    height: 25px;
  }
}