.chart {
    /* max-width: 100%;
    width: 100%;
    padding: 16px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 16px; */
}

.chart_header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.chart_name {
    position: relative;
    font-weight: 500;
    font-size: 16px;
    color: #BEBDBD;
}

.chart_name_absolute {
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #323232;
    top: 0;
    padding-left: 5px;
    cursor: pointer;
}

.chart_filters {
    display: flex;
}

.filter_item {
    padding: 7px 12px;
    font-weight: 500;
    font-size: 14px;
    color: #0B5A45;
}

.filter_item_active {
    padding: 7px 12px;
    font-weight: 500;
    font-size: 14px;
    background: #0B5A45;
    border: 1px solid #0B5A45;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
}

.filter_item:hover {
    cursor: pointer;
    opacity: 0.5;
}

.chart_image {
    margin-top: 10px;
    height: 350px;
}

.currency_list {
    position: absolute;
    top: 20px;
    right: -61px;
    background: gray;
    padding: 5px;
    border-radius: 5px;
    color: white;
    z-index: 1;
    color: white;
}

.currency_list_item {
    margin-bottom: 5px;
    font-size: 12px;
    border-bottom: 1px solid black;
}

.currency_list_item:hover {
    opacity: 0.5;
    cursor: pointer;
}

.item_active {
    opacity: 0.5;
}