.content {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    margin-top: 25px;
}

.inputItem {
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;
}

.inputTitle {
    font-weight: 300;
    margin-bottom: 5px;
}

.inputHandler {
    display: flex;
    position: relative !important;
    width: 100%;
    align-items: center;
}

.input {
    width: 100%;
    height: 100%;
    height: 52px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    padding-left: 16px;
    max-width: 464px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 120%;
    color: #323232;
}

.currency {
    position: absolute;
    right: 0;
    font-weight: 800;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: black;
    top: 35%;
    right: 35px;
}

.currencyToPay {
    position: absolute;
    right: 0;
    font-weight: 400;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: black;
    top: 10%;
    right: 0px;
    background-color: #e7e7e778;
    border-radius: 16px;
}

.currencyInput {
    cursor: default;
    border: none;
    /* background-color: #e7e7e778; */
}

.rate {
    margin-bottom: 10px;
}

.rateInfo {
    width: 100%;
    display: flex;
    align-items: baseline;
    font-weight: 500;
}

.coneInfo {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #757575;
}

.exchangeInfo {
    display: flex;
    flex-flow: column;
    align-items: end;
}

.totalAmount {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #323232;
}

.exchangeRate {
    margin-top: 2px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #BEBDBD;
}

.arrow {
    width: 1005;
    display: flex;
    align-items: center;
    justify-content: center;
}

.additionalInfo {
    opacity: 0.5;
}

.inputsWrapper {
    /* display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between; */
}

.fee {
    display: flex;
    flex-wrap: wrap;
}

.coneImage {
    width: 20px;
    margin-right: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
}