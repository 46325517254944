.wrapper {
    width: 100%;
    height: 100%;
    height: 45px;
    background: #FFFFFF;
    border-radius: 8px;
    max-width: 464px;
    display: flex;
    align-items: center;
    flex-flow: column;
    position: relative;
}

.wrapper:hover {
    cursor: pointer;
}

.selectedItem {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.selectedItem {
    width: 96px;
    padding: 5px 10px;
    cursor: pointer;
    font-weight: 800;
    /* background-color: #f4f4f4; */
}

.dropdown {
    position: absolute;
    min-width: fit-content;
    width: 100%;
    top: 100%;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    max-height: 235px;
    overflow-y: auto;
    margin-top: 4px;
}

.dropdownItem {
    margin-bottom: 5px;
    min-width: fit-content;
    height: 40px;
    display: flex;
    padding: 0 15px 0 10px;
    align-items: center;
}

.currencyImg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.nameHandler {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
}

.network {
    font-weight: 400;
    font-size: 12px;
    font-style: italic;
    color: #0B5A45;
}

.dropdownItem:hover {
    cursor: pointer;
    background-color: #e4e4e4a4;
}

.itemName {
    font-weight: 400px;
}

.fullName {
    padding-left: 8px;
    font-weight: initial;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: rgba(219, 219, 219, 0.541);
}