.infoBlock {
    display: flex;
    flex-flow: column;
    margin-bottom: 10px;
    position: relative;
}

.infoBlock:last-child {
    margin-bottom: 0;
}

.infoBlockTitle {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: #0B5A45;
    margin-bottom: 15px;
}

.infoItem {
    position: relative;
    display: flex;
    flex-flow: column;
    margin-bottom: 15px;
}

.infoItemTitle {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 2px;
}

.infoItemText {
    font-size: 14px;
}

.separator {
    width: 100%;
    height: 1px;
    background-color: #E4E4E4;
}

.copyHandler {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 54.5%;
    right: 60%;
}

.copyHandler:hover {
    cursor: pointer;
    opacity: 0.7;
}

@media (max-width: 500px) {
    .copyHandler {
        right: 60%;
    }
}

@media (max-width: 500px) {
    .copyHandler {
        right: 50%;
    }
}

@media (max-width: 380px) {
    .copyHandler {
        right: 40%;
    }
}