.content {
    display: flex;
    flex-flow: column;
    padding-left: 16px;
    width: 50%;
}

.title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: #BEBDBD;
    margin-bottom: 4px;
}

.amount {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #323232;
}

.image {
    width: 18px;
    margin-bottom: 4px;
}

@media (max-width: 850px) {
   .amount {
    font-size: 12px;
   }
   .title {
    font-size: 10px;
   }
   .image {
    width: 14px;
   }
}