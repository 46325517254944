.wrapper {
  min-height: 90vh;

  padding: 0 40px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.title span {
  font-size: 24px;
  font-weight: 500;
  color: #000;
}

.title {
  margin: 50px 0 20px;
}

.wrapper .login {
  position: relative;
  max-width: 700px;
  width: 100%;
  min-height: 398px;
  background-color: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.wrapper .login .changeLangWrapper {
  position: absolute;
  top: 21px;
  right: 25px;
  cursor: pointer;
}

.wrapper .login .changeLang {
  display: flex;
  align-items: center;
}

.wrapper .login .changeLangWrapper span {
  color: #52575c;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
  margin-right: 13px;
}

.wrapper .login .changeLang svg {
  color: #a0a4a8;
}

.wrapper .login .changeLangWrapper .select {
  border-radius: 4px;
  margin-top: 4px;
}

.wrapper .login .changeLangWrapper .select span {
  margin: 0;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputBlock {
  display: flex;
  flex-direction: column;

  max-width: max-content;
  margin: 0 auto;
}

.inputBlock label {
  font-size: 16px;
  font-weight: 500;
  padding-left: 16px;
  margin-bottom: 4px;
}

.inputBlock input {
  min-width: 326px;
  min-height: 56px;
  margin: 0;
  padding: 0;
  border: 1px solid #e4e4e4;
  border-radius: 8px;

  font-size: 16px;
  font-weight: 400;
  padding: 0 17px;
  letter-spacing: 0.15px;
}

.inputBlock input::-webkit-input-placeholder {
  color: #bababa;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-size: 16px;
}

.inputBlock:nth-child(2) {
  margin-top: 8px;
  position: relative;
}

.inputBlock .typeChangeBtn {
  position: absolute;
  top: 55%;
  right: 16px;
  color: #bababa;

  cursor: pointer;
}

.inputBlock .typeChangeBtn svg {
  min-width: 22px;
}

.loginBtnWrapper {
  margin-top: 24px;
  text-align: center;
}

.loginBtnWrapper .loginBtn {
  min-width: 156px;
  min-height: 36px;
  border-radius: 8px;
  border: 1px solid #10533A;
  background-color: #10533A;
  cursor: pointer;
  color: #ffffff;

  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.165px;
}

.loginBtnWrapper .loginBtn:disabled {
  cursor: default;
}

.noProfileWrapper {
  margin: 16px 0 40px;

  font-size: 14px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.noProfile {
  font-weight: 400;
  color: #787878;
  margin-right: 4px;
}

.noProfileLink {
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}

.noProfileLink:hover {
  text-decoration: underline;
}

.errorWrapper {
  margin: 20px 0 24px;
  min-width: 548px;
  max-width: 548px;
  min-height: 67px;
  border-radius: 4px;
  background-color: #ffecec;
  display: flex;
  align-items: flex-start;

  border: 1px solid #e4e4e4;
}

.errorWrapper img {
  margin-left: 55px;
  margin: 12px 13px 0 55px;
  max-height: max-content;
}

.errorWrapper .errorContent {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.165px;
  color: #949494;
}

.errorWrapper .errorContent .errorTitle {
  font-weight: 500;
  display: block;
  padding-bottom: 3px;
}

.errorWrapper .errorContent a {
  color: #000;
  font-weight: 500;
}

.addErrorContent {
  line-height: 24px;
  margin: 8px 0 7px 14px;
}

.twoFactAuthWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);

  z-index: 9999;
}

.twoFactAuthWrapper .twoFactAuthContent {
  position: relative;
  max-width: 600px;
  width: 100%;
  min-height: 210px;
  height: max-content;
  border-radius: 8px;
  background-color: #fff;

  -webkit-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
  box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.closeBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 0;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.twoFactAuthWrapper .twoFactAuthContent .title {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-top: 30px;
}

.twoFactAuthWrapper .twoFactAuthContent .authContent {
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.twoFactAuthWrapper .twoFactAuthContent .footer {
  margin-top: 20px;
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: max-content;
}

.twoFactAuthWrapper .twoFactAuthContent .footer button {
  width: 154px;
  height: 34px;
  margin: 0 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.165px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #10533A;
}

.twoFactAuthWrapper .twoFactAuthContent .footer button:nth-child(1) {
  background-color: #fff;
  color: #323232;
}

.twoFactAuthWrapper .twoFactAuthContent .footer button:nth-child(2) {
  background-color: #10533A;
  color: #ffffff;
}

.authContent input {
  min-width: 300px;
  min-height: 46px;
  margin: 0;
  padding: 0;
  border: 1px solid #e4e4e4;
  border-radius: 8px;

  font-size: 16px;
  font-weight: 400;
  padding: 0 17px;
  letter-spacing: 0.15px;
}

.authContent input::-webkit-input-placeholder {
  color: #bababa;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-size: 16px;
}

.inputWithIcon {
  min-width: 295px !important;
  min-height: 56px !important;
  padding: 0 45px 0px 17px !important;
}

@media (max-width: 700px) {
  .loginBtnWrapper .loginBtn {
    min-height: 50px;
    max-width: 288px;
    width: 100%;
  }
}

@media (max-width: 660px) {
  .errorWrapper {
    max-width: 415px;
    min-width: 415px;
  }

  .errorWrapper img {
    margin-left: 25px;
  }

  .errorWrapper .errorContent {
    padding: 0 0 14px 0;
  }
}

@media (max-width: 520px) {
  .wrapper .login input {
    min-width: 300px;
  }

  .inputWithIcon {
    padding-right: 50px !important;
    min-width: 267px !important;
  }

  .errorWrapper {
    padding: 0 20px;
    max-width: 300px;
    min-width: 300px;
  }

  .errorWrapper img {
    display: none;
  }
}

@media (max-width: 460px) {
  .title span {
    font-size: 20px;
  }

  .inputBlock label {
    font-size: 14px;
  }

  .wrapper .login input {
    min-width: 260px;
    min-height: 46px;
  }

  .inputWithIcon {
    padding-right: 50px !important;
    min-width: 227px !important;
    min-height: 46px !important;
  }

  .wrapper .login .changeLangWrapper {
    right: 10px;
  }

  .errorWrapper {
    max-width: 260px;
    min-width: 260px;
  }

  .loginBtnWrapper .loginBtn {
    width: 100%;
  }

  .twoFactAuthWrapper .twoFactAuthContent .title {
    max-width: 300px;
    margin: 20px 0 0 0;
    text-align: center;
    font-size: 20px;
  }
}

@media (max-width: 430px) {
  .wrapper .login input {
    min-width: 230px;
    min-height: 50px;
  }

  .inputWithIcon {
    padding-right: 40px !important;
    min-width: 207px !important;
    min-height: 50px !important;
  }

  .errorWrapper {
    max-width: 220px;
    min-width: 220px;
  }
}

@media (max-width: 390px) {
  .errorWrapper {
    max-width: 210px;
    min-width: 210px;
  }

  .wrapper .login input {
    min-width: 205px;
  }

  .inputWithIcon {
    padding-right: 40px !important;
    min-width: 182px !important;
    min-height: 50px !important;
  }

}