.successToast {
  background-color: #67ac5c !important;
  color: #fff !important;
}

.errorToast {
  background-color: #e25241 !important;
  color: #fff !important;
}

.tostWrapper {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 12px;
}

.toastMessage {
  width: 85%;
}