.wrapper {
    margin-left: 60px;
    background-color: #f8f8f8;

    max-width: 100%;
    overflow-x: auto;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.wrapperFull {
    margin-left: 260px;
}

.wrapper h2 {
    margin: 31px 0 5px 40px;

    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.25px;
}

.table {
    margin: 40px 100px 0 40px;
    max-height: max-content;
    max-width: 844px;
    min-width: 836px;
}

.table .header {
    max-height: 20px;
    display: flex;
    /* width: 836px;  */
    width: 100%;
}

.table .header div:nth-child(1) svg {
    position: absolute;
    top: 1px;
    right: -19px;
    font-size: 16px;
    color: #bababa;
}

.table .header div:nth-child(1) {
    margin-left: 16px;
    min-width: 76px;
}

.table .header div:nth-child(2) {
    margin-left: 20px;
    min-width: 80px;
    /* margin-left: 13.61%; */
}

.table .header div:nth-child(3) {
    margin-left: 22px;
    min-width: 148px;
    /* margin-left: 11.7%; */
}

.table .header div:nth-child(4) {
    margin-left: 18px;
    min-width: 150px;
    /* margin-left: 10%; */
}

.table .header div:nth-child(5) {
    margin-left: 18px;
    min-width: 148px;
    /* margin-left: 7.3%; */
}

.table .header div:nth-child(6) {
    margin-left: 5px;
    min-width: 92px;
    /* margin-left: 9.4%; */
}

.table .header div {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #757575;
}

.table .pages {
    margin: 30px auto;
    width: 100%;
    display: flex;
    justify-content: center;

    list-style-type: none;
}

.table .pages li {
    margin: 4px;
}

.table .pages .step {
    color: #323232;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.8px;
    margin: 0 25px;
    text-transform: uppercase;
    cursor: pointer;
}

.table .pages .step[aria-disabled="true"] {
    cursor: default;
    color: #bebdbd;
}

.pages .pageNum {
    cursor: pointer;
    color: #bebdbd;
}

.break {
    cursor: pointer;
    color: #bebdbd;
}

.selected {
    color: #323232 !important;
    transform: scale(1.15, 1.15);
}

.noData {
    margin: 80px auto 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 316px;
}

.noData h4 {
    margin: 16px 0 8px;
    color: #323232;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.noData span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #757575;
}

.errorPopupWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(3px);

    z-index: 9999;
}

.errorPopupWrapper .errorPopup {
    margin-left: 0 !important;
    padding: 60px;
    position: relative;
    max-width: 240px;
    width: 100%;
    min-height: 150px;
    max-height: max-content;
    border-radius: 8px;
    background-color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #757575;

    -webkit-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
    -moz-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
    box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
}

.errorPopupWrapper .errorPopup .closeBtn {
    position: absolute;
    right: 14px;
    top: 14px;
    padding: 0;
    border: none;
    background-color: inherit;
    cursor: pointer;
}

.closeBtn svg {
    min-height: 24px;
    min-width: 24px;
    opacity: 0.54;
}

.token {
    width: 100px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 860px) and (min-width: 700px) {
    .noDataFull {
        margin: 80px auto 20px 40%;
    }
}

@media (max-width: 700px) {
    .wrapper {
        margin: 0 auto;
    }

    .wrapper h2 {
        margin: 31px 0 0 20px;
    }

    .errorPopupWrapper .errorPopup {
        margin: 0 20px !important;
        padding: 40px;
    }
}