.wrapper {
  position: relative;
  height: 83px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;
  align-self: end;
  width: calc(100% - 260px);
}

.full {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.wrapper .allConeWrapper {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.wrapper .allConeWrapper .allCone {
  border-radius: 4px;
  min-height: 50px;
  min-width: 82px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
}

.wrapper .allConeWrapper .allCone span:nth-child(1) {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.165px;
}

.wrapper .allConeWrapper .allCone span:nth-child(2) {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #bfbfbf;
}

.buyBtn {
  padding: 12px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
  background: #0B5A45;
  border: 1px solid #0B5A45;
  border-radius: 8px;
  margin-right: 32px;
  margin-left: auto;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  text-align: center;
}

.visible {
  display: initial !important;
}

.disabled {
  cursor: default;
  background-color: #0b5a4565;
  border: none;

}

div[data-title]:hover::after {
  content: attr(data-title);
  position: absolute;
  top: 96%;
  z-index: 10;
  padding: 5px 10px;
  background: rgb(16, 83, 58);
  color: #ffffff;
  border-radius: 8px;
  right: 3%;
  font-weight: initial;
}

@media (max-width: 1190px) {
  .wrapper .allConeWrapper {
    display: none;
  }

  .buyBtn {
    display: none;
    margin: 10px 0;
  }

  .total {
    max-height: 40px;
    max-width: 40px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);

    cursor: pointer;
  }

  .totalContentWrapper {
    position: absolute;
    top: 84px;
    width: 100%;
    padding: 10px 0;
    border: 1px solid #e4e4e4;
    border-top: none;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3000;
  }

  .totalContent {
    position: relative;
    border: 1px solid #bebdbd;
    min-height: 71px;
    min-width: 318px;
    border-radius: 8px;
    margin: 10px 0 !important;
    display: flex;
    flex-direction: column;
  }

  .totalContent span:nth-child(1) {
    font-weight: 700;
    font-size: 24px;
    color: #323232;
    margin: 10px 10px 0 10px;
  }

  .totalContent span:nth-child(2) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #bebdbd;
    margin-left: 10px;
  }

  .blur {
    min-height: 100vh;
    min-width: 100vw;
    position: fixed;
    top: 84px;
    z-index: 2100;
    backdrop-filter: blur(3px);
  }
}

@media (max-width: 700px) {
  .wrapper {
    height: 59px;
    width: calc(100% - 60px);
  }

  .total {
    right: 12px;
  }

  .totalContentWrapper {
    top: 60px;
    padding: 20px 0;
  }

  .blur {
    top: 60px;
    position: absolute;
    right: 0;
  }
}