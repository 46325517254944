.itemCard {
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
    background: #F8F8F8;
    border-radius: 4px;

}

.generalInfo {
    display: flex;
    flex-flow: column;
}

.title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: #757575;
    margin-bottom: 4px;
}

.date {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: #BEBDBD;
}

.valueInfo {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
}

.value {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
    margin-bottom: 4px;
}

.totalValue {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: #BEBDBD;
}

.green {
    color: #2AC769;
}

.red {
    color: #FF6464;
}