.content {
    display: flex;
    align-items: center;
}

.currencyAmount {
    position: relative;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.25px;
    color: #323232;
}

.percentValue {
    position: absolute;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    top: 25px;
    right: -45px;
}

.cardTitle {
    height: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #BEBDBD;
    margin-bottom: 4px;
}

.cardTitleWithImage {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.25px;
    color: #757575;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
@media (max-width: 850px) {
    .currencyAmount {
        font-size: 18px;
    }
 }