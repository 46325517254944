.walletWrapperCone {
  max-width: 380px;
  min-width: 380px;
  min-height: 135px;
  border-radius: 8px;
  background: linear-gradient(278.4deg, #b4b3b1 0%, #e4e4e4 100%);

  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  margin-right: 24px;
  position: relative;
}

.walletWrapperBtc {
  max-width: 380px;
  min-width: 380px;
  min-height: 146px;
  border-radius: 8px;
  background: linear-gradient(278.4deg, #10533A 0%, #157452 100%);
  color: #ffffff;
  margin-bottom: 24px;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
}

.walletWrapperBtc .confirmDeleteWrapper {
  color: #323232;
}

.header {
  margin: 20px 20px 0 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.header img {
  max-width: 24px;
  max-width: 24px;
}

.header span {
  margin-left: 8px;
  font-weight: 500;
  line-height: 25px;
  font-size: 24px;
  letter-spacing: 0.25px;
}

.header span[data-maxwidth="true"] {
  max-width: 229px;
  position: relative;
  overflow: hidden;
}

.header span[data-maxwidth="true"]:after {
  content: "";
  text-align: right;
  position: absolute;
  top: 2px;
  right: -10px;
  width: 35%;
  height: 1em;
  background: linear-gradient(to right, rgba(199, 198, 197, 0), #c1c1bf 90%);
  pointer-events: none;
}

.header span[data-maxwidth="true"][data-currency="BTC"]:after {
  background: linear-gradient(to right, rgba(251, 229, 151, 0), #10533A 90%);
}

.header {
  cursor: pointer;
}

.header .delete img {
  max-width: 20px;
  max-height: 20px;
}

.delete {
  cursor: pointer;
  margin-left: 15px;
}

.address {
  margin-right: 10px;
  font-weight: bold;
}

.address span:nth-child(1) {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-left: 20px;
}

.address span:nth-child(2) {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #757575;
  margin-left: 4px;
}

.footer {
  margin: 2px 10px 10px 20px;
  overflow: hidden;
}

.confirmDeleteWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);

  z-index: 9999;
}

.confirmDeleteWrapper .confirmDeleteContent {
  position: relative;
  max-width: 500px;
  width: 100%;
  min-height: 210px;
  margin: 0 20px;
  height: max-content;
  border-radius: 8px;
  background-color: #fff;

  -webkit-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
  box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.closeBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 0;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.confirmDeleteWrapper .confirmDeleteContent .title {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-top: 30px;
  align-self: flex-start;
  margin-left: 20px;
}

.confirmDeleteWrapper .confirmDeleteContent .content {
  margin: 40px 20px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.confirmDeleteWrapper .confirmDeleteContent .footer {
  width: 94%;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}

.confirmDeleteWrapper .confirmDeleteContent .footer button {
  width: 154px;
  height: 34px;
  margin: 0 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.165px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #10533A;
}

.confirmDeleteWrapper .confirmDeleteContent .footer button:nth-child(1) {
  background-color: #fff;
  color: #323232;
  border: none;
  color: rgb(16, 83, 58);
  font-weight: bold;
}

.confirmDeleteWrapper .confirmDeleteContent .footer button:nth-child(2) {
  background-color: #10533A;
  color: #ffffff;
}

.footer {
  position: relative;
  align-items: center;
  flex-flow: column;
  margin-top: 20px;
}

.footer span:after {
  content: "";
  text-align: right;
  position: absolute;
  top: 3px;
  right: 0px;
  width: 10%;
  height: 1em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #b4b3b1 90%);
  pointer-events: none;
}

.footer span[data-coin="BTC"]:after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #10533A 90%);
}

.buyBtn {
  width: 56px;
  height: 16px;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 2px 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0B5A45;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.manipulate {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 5px;
}

.balance {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: initial;
}

.adressValue {
  opacity: 0.7;
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
  margin-top: 5px;
}

.footerBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 20px 10px 20px;
}

.error {
  content: attr(data-title);
  position: absolute;
  top: 100%;
  z-index: 100000;
  padding: 5px 10px;
  background: rgb(16, 83, 58);
  color: #ffffff;
  border-radius: 8px;
  right: -25%;
  width: 135%;
}

.disabled {
  opacity: 0.4;
}

.bold {
  font-weight: bold;
}

.green {
  color: rgb(16, 83, 58);
}

@media (max-width: 540px) {
  .error {
    width: 100%;
    right: 0%;
  }
}

@media (max-width: 420px) {
  .confirmDeleteWrapper .confirmDeleteContent {
    max-width: 320px;
  }

  .confirmDeleteWrapper .confirmDeleteContent .content {
    max-width: 288px;
    max-height: max-content;
    word-wrap: break-word;
  }

  .confirmDeleteWrapper .confirmDeleteContent .footer {
    display: flex;
    margin-bottom: 16px;
  }

  .confirmDeleteWrapper .confirmDeleteContent .footer button {
    width: 134px;
    height: 50px;
  }

  .walletWrapperBtc,
  .walletWrapperCone {
    max-width: 320px;
    min-width: 320px;
  }

  .header span {
    font-size: 18px;
  }

  .error {
    width: 90%;
    right: 0%;
  }
}