.wrapper {
    width: 94%;
    height: 100%;
    height: 52px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 464px;
    display: flex;
    align-items: center;
    flex-flow: column;
    position: relative;
}

.wrapper:hover {
    cursor: pointer;
}

.selectedItem {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selectedItem {
    cursor: pointer;
}

.dropdown {
    position: absolute;
    width: 100%;
    top: 100%;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    max-height: 235px;
    overflow-y: auto;
}

.dropdownItem {
    border-bottom: 1px solid #E4E4E4;
    height: 40px;
    display: flex;
    align-items: center;

}

.dropdownItem:hover {
    cursor: pointer;
    background-color: #e4e4e4a4;
}

.itemName {
    padding-left: 16px;
    overflow-x: hidden;
    width: 90%;
    text-overflow: ellipsis;
}

.fullName {
    padding-left: 8px;
    font-weight: initial;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: rgba(219, 219, 219, 0.541);
}

.textWrap {
    overflow-x: hidden;
}