.wrapper {
    margin-left: 60px;
    background-color: #f8f8f8;
    max-width: 100%;
    overflow-x: auto;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.wrapper h2 {
    margin: 31px 0 5px 40px;

    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.25px;
}

.wrapperFull {
    margin-left: 260px;
}

.content {
    margin: 12px 40px 0 40px;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    gap: 34px;
}

.infoContent {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    width: 50%;
    margin-right: 15px;
}

.card {
    /* max-width: 628px; */
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 16px;
    /* display: flex; */
}

.cardContent {
    padding: 16px;
}

.cardTitle {
    height: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #BEBDBD;
    margin-bottom: 10px;
}

.sections {
    display: flex;
    /* flex-flow: column; */
}

.section {
    display: flex;
    width: 50%;
}


.separator {
    border-right: 1px solid #E4E4E4;
}

.sectionContent {
    display: flex;
    flex-flow: column;
}

.infoList {
    display: flex;
    width: 45%;
}

.list {
    display: flex;
    flex-flow: column;
    max-height: 610px;
    overflow-y: auto;
}

@media (max-width: 700px) {
    .wrapper {
        margin: 0 auto;
    }

    .wrapper h2 {
        margin: 31px 0 0 20px;
    }
}

@media (max-width: 1320px) {
    .content {
        margin: 12px 40px 0 40px;
        display: flex;
        flex-flow: column;
        flex-wrap: wrap;
    }

    .infoContent {
        display: flex;
        flex-flow: column;
        flex-wrap: wrap;
        width: 100%;
        margin-right: 0;
    }

    .infoList {
        display: flex;
        width: 100%;
    }
}