.wrapper {
  min-height: 90vh;

  padding: 0 40px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.title span {
  font-size: 24px;
  font-weight: 500;
  color: #000;
}

.title {
  margin: 50px 0 20px;
  text-align: center;
}

.wrapper .login {
  max-width: 700px;
  width: 100%;
  min-height: 398px;
  background-color: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 20px;
}

.inputBlock {
  display: flex;
  flex-direction: column;
}

.inputBlock label {
  font-size: 16px;
  font-weight: 500;
  padding-left: 16px;
  margin-bottom: 4px;
}

.inputBlock input {
  min-width: 326px;
  min-height: 56px;
  margin: 0;
  padding: 0;
  border: 1px solid #e4e4e4;
  border-radius: 8px;

  font-size: 16px;
  font-weight: 400;
  padding: 0 17px;
  letter-spacing: 0.15px;
}

.inputBlock input::-webkit-input-placeholder {
  color: #bababa;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-size: 16px;
}

.loginBtnWrapper {
  margin-top: 30px;
}

.loginBtnWrapper .loginBtn {
  min-width: 156px;
  min-height: 36px;
  border-radius: 8px;
  border: 1px solid #10533A;
  background-color: #10533A;
  cursor: pointer;
  color: #ffffff;

  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.165px;
}

.loginBtnWrapper .loginBtn:disabled {
  cursor: default;
}

.noProfileWrapper {
  margin-top: 16px;

  font-size: 14px;
}

.loginWrapper {
  margin-top: 6px;

  font-size: 14px;
}

.noProfile {
  font-weight: 400;
  color: #787878;
  margin-right: 4px;
}

.noProfileLink {
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}

.noProfileLink:hover {
  text-decoration: underline;
}

.noProfileLinkDisabled {
  font-weight: 500;
  cursor: default;
  text-decoration: none;
  color: #000;

  opacity: 0.6;
}

.info {
  text-align: center;
  font-weight: 400;
  margin: 2px auto 0;
  max-width: 330px;
  font-size: 10px;
  color: #000;
  line-height: 18px;
  letter-spacing: -0.165px;

  opacity: 0.7;
}

@media (max-width: 700px) {
  .inputBlock {
    padding: 0 16px;
    position: relative;
  }
  .inputBlock input {
    max-width: 234px;
    min-width: 234px;
    margin: 24px auto;
  }

  .inputBlock label {
    position: absolute;
    left: 40px;
  }
}

@media (max-width: 450px) {
  .inputBlock label {
    left: 30px;
  }
}

@media (max-width: 420px) {
  .inputBlock label {
    left: 20px;
  }
  .noProfileWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
