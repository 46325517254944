.content {
    margin-top: 25px;
    display: flex;
    flex-flow: column;
}

.qrdata {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.qrdata_info {
    display: flex;
    flex-flow: column;
}

.copyData {
    margin-top: 20px;
    display: flex;
    width: 100%;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

p {
    margin: 0;
    font-size: 12px;
    color: #757575
}

p:first-child {
    margin-top: 20px;
    margin-bottom: 5px;
}

.delim {
    margin-top: 5px;
    width: 100%;
    height: 1px;
    background-color: #E4E4E4;
}

.copyDataItem {
    margin-top: 10px;
    display: flex;
    flex-flow: column;
    font-weight: bold;
    font-size: 12px;
    width: 100%;
    justify-content: space-between;
    width: fit-content;
    min-width: 165px;
}

.copyDataItemLink {
    display: flex;
}

.copyHandler {
    margin-left: 25px;
}

.copyHandler:hover {
    cursor: pointer;
}

.info {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.text {
    font-weight: 300;
}
@media(max-width: 350px){
    .copyData {
        justify-content: center;
    }
}