.popup {
    padding: 20px 15px;
    position: absolute;
    max-width: 451px;
    max-height: 156px;
    width: 100%;
    top: 45%;
    right: 0;
    background-color: #fff;
    z-index: 2;
    border-radius: 8px;
    box-shadow: -5px 2px 25px 14px rgba(0, 0, 0, 0.25);
}

.title {
    position: relative;
    width: 100%;
    ;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #323232;
    margin-bottom: 20px;
}

.content {}

.message {
    font-size: 14px;
    margin-bottom: 15px;
}

.btns {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.cancel {
    max-width: 205px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;
    color: #0B5A45;
    font-weight: bold;
}

.cancel:hover {
    cursor: pointer;
}

.confirm {
    height: 43px;
    max-width: 205px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0B5A45;
    border-radius: 8px;
    color: #fff;
}

.confirm:hover {
    cursor: pointer;
}

.closeBtn {
    cursor: pointer;
    position: absolute;
    width: 16px;
    height: 16px;
    right: 15px;
    top: 25px;
    z-index: 2;
}

@media (max-width: 540px) {
    .popup {
        right: 0;
        width: 93%;
    }
}