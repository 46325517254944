img {
    margin-right: 5px;
}

.disabled {
    opacity: 0.4;
    pointer-events: none;
}

.cardWrapper {
    height: 100%;
    max-width: 380px;
    min-width: 380px;
    min-height: 146px;
    border-radius: 8px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-right: 24px;
}

.cardWrapper:hover {
    cursor: pointer;
}

.cardtitle {
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #0B5A45;
    padding: 22px 22px 0 22px;
}

.cardCurrency {
    display: flex;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #757575;
    padding: 0px 22px 22px 22px;
}

@media (max-width: 420px) {
    .cardWrapper {
        max-width: 320px;
        min-width: 320px;
    }

    .cardtitle {
        font-size: 18px;
    }
}