.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);

  z-index: 9999;
}

.content,
.consfirmAdding {
  position: relative;
  max-width: 800px;
  width: 100%;
  min-height: 375px;
  height: max-content;
  border-radius: 8px;
  background-color: #fff;

  -webkit-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
  box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
}

.consfirmAdding {
  max-width: 580px;
  min-height: 200px;

  display: flex;
  flex-direction: column;

  padding: 20px;
}

.consfirmAdding h4 {
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  margin: 30px 10px;
}

.consfirmAdding span {
  font-size: 16px;
  color: #323232;
  text-align: center;
}

.consfirmAdding .btnGroup {
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
}

.consfirmAdding button {
  border-radius: 8px;
  min-width: 180px;
  min-height: 50px;
  margin: 0 20px;
}

.consfirmAdding button {
  border: 1px solid #10533A;
  background-color: #10533A;
  cursor: pointer;
  color: #ffffff;

  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.165px;
}

.consfirmAdding button:nth-child(1) {
  background-color: #fff;
  color: #323232;
}

.closeWalletBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 0;
  border: none;
  background-color: inherit;
  cursor: pointer;
  color: #323232;
}

.closeWalletBtn svg {
  height: 18px;
  width: 18px;
}

.content .steps {
  display: flex;
  justify-content: center;
}

.content .steps span {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #bababa;
}

.content .steps .step {
  border-bottom: 2px solid #bebdbd;
  margin: 36px 8px 0;
  text-align: center;
  min-width: 144px;
}

.content .steps .completedStep {
  border-bottom: 2px solid #10533A;
  margin: 36px 8px 0;
  text-align: center;
  min-width: 144px;
}

.content .steps .completedStep span {
  color: #10533A;
}

.content .steps .activeStep {
  margin: 36px 8px 0;
  text-align: center;
  min-width: 144px;
  border-bottom: 2px solid #10533A;
}

.content .steps .activeStep span {
  color: #000;
}

.content .addWallet {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content .addWallet .addWalletTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
}

.content .addWallet .addWalletAddress {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.content .addWallet .addWalletAddress label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-left: 17px;
}

.content .addWallet .addWalletAddress .addWalletInput {
  min-width: 324px;
  min-height: 54px;
  margin: 0;
  border-radius: 8px;
  padding-left: 17px;
  padding-right: 17px;
  border: 1px solid #e4e4e4;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.content .addWallet .addWalletAddress .addWalletInput::-webkit-input-placeholder {
  color: #bababa;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-size: 16px;
}

.content .addWallet .blockchainBlock {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.content .addWallet .blockchainBlock label {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-left: 8px;
}

.blockchainEl {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.blockchainEl+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  position: relative;
}

.blockchainEl+label::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid #dadada;
  min-height: 16px;
  min-width: 16px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.blockchainEl:not(:disabled):not(:checked)+label:hover::before {
  border-color: #10533A;
}

.blockchainEl:not(:disabled):active+label::before {
  background-color: #10533A;
  border-color: #10533A;
}

.blockchainEl:checked+label::before {
  border-color: #10533A;
  background-color: #10533A;
}

.blockchainEl+label svg {
  position: absolute;
  color: #fff;
  left: -1px;

  height: 22px;
  width: 22px;
}

.blockchainBlock span {
  margin-left: 8px;
}

.content .nextStep {
  margin-top: 34px;
  display: flex;
  justify-content: center;
}

.content .nextStep button {
  max-width: 122px;
  width: 186px;
  min-height: 36px;
  border-radius: 8px;
  border: 1px solid #10533A;
  background-color: #10533A;
  cursor: pointer;
  color: #ffffff;

  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.165px;
}

.signWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signWrapper .signTitle {
  color: #000;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;

  margin: 40px 0 24px;
}

.signContent {
  max-width: 440px;
}

.signText {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.165px;
  opacity: 0.7;
}

.walletInput {
  margin-top: 12px;
}

.walletInput span {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.165px;
  opacity: 0.7;
}

.walletInput .walletNumber {
  margin-left: 4px;
  color: #000;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.165px;
  opacity: 1;
}

.signArea {
  margin-top: 13px;
  display: flex;
  flex-direction: column;
}

.signArea span, .signatureWrapper span {
  color: #000;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  margin: 0 0 9px 14px;
}

.signArea textarea {
  background-color: #f7f7f7;
  border: 1px solid #e4e4e4;
  border-radius: 8px;

  resize: none;

  padding: 12px;

  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  height: 80px;
  width: 416px;
  margin-bottom: 16px;
}

.walletsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.walletsWrapper {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.walletsWrapper .wallet {
  cursor: pointer;
  margin-top: 12px;
  width: 360px;
  height: 56px;
  border: 1px solid #10533A;
  border-radius: 8px;

  display: flex;
  align-items: center;
}

.walletsWrapper .wallet img {
  margin: 0 12px 0 16px;
  max-height: 36px;
  max-width: 36px;
}

.walletsWrapper .wallet span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.walletsWrapper div[data-open="true"] {
  border-radius: 8px 8px 0 0;
}

.walletsWrapper div:nth-child(2) svg {
  margin-left: auto;
  margin-right: 14px;
  color: #10533A;
}

.walletsWrapper div:nth-child(3) {
  top: 98px;
  position: absolute;
  background-color: #fff;
  overflow: hidden;

  border-radius: 0 0 8px 8px;
  border-top: 0;
}

.nextPrevSteps {
  margin: 30px 0 40px;
  display: flex;
  justify-content: center;
}

.nextPrevSteps button {
  margin: 0 6px;
  max-width: 122px;
  width: 186px;
  min-height: 36px;
  border-radius: 8px;
  border: 1px solid #10533A;
  background-color: #10533A;
  cursor: pointer;
  color: #ffffff;

  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.165px;
}

.nextPrevSteps button:nth-child(1) {
  background-color: #fff;
  color: #323232;
}

.signatureWrapper {
  margin-top: 13px;
  display: flex;
  flex-direction: column;
}

.signatureWrapper input {
  min-width: 400px;
  min-height: 54px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  padding: 0 17px;

  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-weight: 400;
}

.signatureWrapper .walletsWrapper {
  margin-left: 17px;
}

.succesVerify {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.succesVerify img {
  margin-top: 60px;
  max-height: 48px;
  max-width: 48px;
}

.succesVerify h2 {
  margin: 20px 0 8px;
}

.succesVerify span {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.165px;
  opacity: 0.7;
}

.succesVerify button {
  margin: 40px 20px 0;
  max-width: 162px;
  width: 186px;
  min-height: 36px;
  border-radius: 8px;
  border: 1px solid #10533A;
  background-color: #10533A;
  cursor: pointer;
  color: #ffffff;

  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.165px;
}

.succesVerify .btnGroup button:nth-child(1) {
  border: 1px solid #10533A;
  background-color: #fff;
  color: #323232;
}

@media (max-width: 900px) {

  .wrapper .content,
  .wrapper .consfirmAdding {
    margin: 0 24px;
  }
}

@media (max-width: 550px) {
  .content .nextStep button {
    min-width: 288px;
    min-height: 50px;
  }

  .content .addWallet .addWalletAddress .addWalletInput {
    min-height: 48px;
    min-width: 252px;
  }

  .content .steps div {
    min-width: 140px !important;
  }

  .content .steps[data-step="1"] div:nth-child(2),
  .content .steps[data-step="1"] div:nth-child(3) {
    min-width: 66px !important;
  }

  .content .steps[data-step="1"] div:nth-child(2) span,
  .content .steps[data-step="1"] div:nth-child(3) span {
    display: none;
  }

  .content .steps[data-step="2"] div:nth-child(1),
  .content .steps[data-step="2"] div:nth-child(3) {
    min-width: 81px !important;
  }

  .content .steps[data-step="2"] div:nth-child(2) {
    min-width: 109px !important;
  }

  .content .steps[data-step="2"] div:nth-child(1) span,
  .content .steps[data-step="2"] div:nth-child(3) span {
    display: none;
  }

  .content .steps[data-step="3"] div:nth-child(1),
  .content .steps[data-step="3"] div:nth-child(2) {
    min-width: 81px !important;
  }

  .content .steps[data-step="3"] div:nth-child(3) {
    min-width: 110px !important;
  }

  .content .steps[data-step="3"] div:nth-child(1) span,
  .content .steps[data-step="3"] div:nth-child(2) span {
    display: none;
  }

  .content .steps[data-step="4"] div:nth-child(1),
  .content .steps[data-step="4"] div:nth-child(2) {
    min-width: 81px !important;
  }

  .content .steps[data-step="4"] div:nth-child(1) span,
  .content .steps[data-step="4"] div:nth-child(2) span {
    display: none;
  }

  .signArea {
    max-width: max-content;
    margin: 13px auto 0;
  }

  .signArea textarea {
    height: 88px;
    width: 264px;
  }

  .signContent {
    max-width: 288px;
  }

  .walletsWrapper .wallet {
    max-width: 288px;
  }

  .signatureWrapper input {
    min-width: 252px;
  }

  .nextPrevSteps button {
    min-width: 134px;
    min-height: 50px;
  }

  .succesVerify {
    max-width: 288px;
    margin: 0 auto 16px;
    text-align: center;
  }

  .succesVerify button {
    min-height: 50px;
    min-width: 288px;
  }

  .succesVerify .btnGroup {
    display: flex;
  }

  .succesVerify .btnGroup button {
    min-width: 162px;
  }
}

@media (max-width: 530px) {
  .wrapper .consfirmAdding button {
    margin: 0 10px;
    min-width: 120px;
  }
}

@media (max-width: 430px) {
  .succesVerify .btnGroup button {
    margin-left: 10px;
    margin-right: 10px;
    max-width: 134px;
    min-width: 134px;
  }
}

@media (max-width: 360px) {
  .content .nextStep button {
    margin-bottom: 20px;
  }
}

@media screen and (max-height: 660px) {
  .wrapper {
    position: absolute;
  }
}