.changeLangWrapper {
    position: absolute;
    top: 21px;
    right: 25px;
    cursor: pointer;
}

.changeLang {
    display: flex;
    align-items: center;
}

.changeLangWrapper span {
    color: #52575c;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1px;
    margin-right: 13px;
}

.changeLang svg {
    color: #a0a4a8;
}

.changeLangWrapper .select {
    border-radius: 4px;
    margin-top: 4px;
}

.changeLangWrapper span {
    margin: 0;
}

@media (max-width: 460px) {
    .wrapper .login .changeLangWrapper {
        right: 10px;
    }
}