  .passValidateWrapper {
      position: absolute;
      color: #ff6464;
      max-width: 362px;
      margin: 2px auto;
      font-size: 14px;
      font-weight: 400;
      background-color: #fff;
      z-index: 999;
      right: -140px;
      top: -130px;
      padding: 10px;
      border-radius: 8px;
      cursor: default;
      border: 1px solid #e4e4e4;
  }

  .passValidateWrapper ul {
      list-style-type: disc;
      margin: 2px 0;
      padding-left: 17px;
  }


  @media (max-width: 700px) {
      .passValidateWrapper {
          position: static;
      }
  }