.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0px;
    bottom: -25px;
}